
import Vue from "vue";
import axios from "@/plugins/axios";
import i18n from "@/i18n";
import {Survey} from "@/models/survey";
import {CurrentSurvey} from "@/models/report/ProgressReport/currentsurvey";
import {CountrySurvey} from "../../models/report/ProgressReport/countrysurvey";
import {ResponseRate} from "../../models/report/ProgressReport/responserate";

export default Vue.extend({
  name: "ProgressReport",
  data: () => ({
    survey: {} as Survey,
    actualCurrentSurvey: [] as CurrentSurvey[],
    cumulativeCurrentSurvey: [] as CurrentSurvey[],
    totalSurvey: [] as CurrentSurvey[],
    byCountrySurvey: [] as CountrySurvey[],
    responseRate: [] as ResponseRate[],
    yearsEndTotal: [] as CurrentSurvey[],
    loadingActualCurrent: false,
    loadingCumulativeCurrent: false,
    loadingTotal: false,
    loadingYearsEndTotal: false,
    loadingByCountry: false,
    loadingResponseRate: false,
  }),
  computed: {
    actualMonth: {
      get() {
        const date = new Date();
        const month = date.toLocaleString(i18n.locale, {month: "long"});

        return month;
      },
    },

    lang: {
      get() {
        return i18n ? i18n.locale : "en";
      },
    },
    headerCountryColumns() {
      return [
        {
          text: i18n.t("reports.progressreport.latestsurveyyear"),
          value: "year",
        },
        {text: i18n.t("basic.country"), value: "country"},
        {
          text: i18n.t("reports.progressreport.numberofsurvey"),
          value: "submissionCount",
        },
      ];
    },
    headerRateColumns() {
      return [
        {text: i18n.t("basic.year"), value: "year"},
        {
          text: i18n.t("reports.progressreport.yearnmos"),
          value: "nmoYearCount",
        },
        {
          text: i18n.t("reports.progressreport.historicalnmos"),
          value: "nmoHistoricalCount",
        },
        {
          text: i18n.t("reports.progressreport.totalnmos"),
          value: "nmoTotalCount",
        },
      ];
    },
  },
  methods: {
    headersCommonColumns(haveYear: boolean) {
      const columns = [
        {text: "PWH", value: "pwhCount"},
        {text: "VWD", value: "vwdCount"},
        {text: "OBD", value: "obdCount"},
        {
          text: i18n.t("reports.progressreport.totalpatients"),
          value: "patientCount",
        },
        {text: "NMOs", value: "nmoCount"},
      ];
      if (haveYear) {
        columns.unshift({
          text: i18n.t("reports.progressreport.surveyyear"),
          value: "year",
        });
      }
      return columns;
    },
    loadingInAction() {
      this.loadingResponseRate = true;
      this.loadingActualCurrent = true;
      this.loadingCumulativeCurrent = true;
      this.loadingTotal = true;
      this.loadingYearsEndTotal = true;
      this.loadingByCountry = true;
    },
    excelYearsEndTotal(){
      axios
          .get("/progressreport/surveyYearEndNumber/export", {
            responseType: "blob",
          })
          .then((res) => {
            const fileName =
                i18n.t("countryoverview.pagetitle") +
                "-" +
                i18n.t("reports.progressreport.yearsEndTotal");
            this.downloadExcel(fileName, res.data);
          });
    },
    excelActualNumber() {
      axios
          .get("/progressreport/surveyactualnumber/export", {
            responseType: "blob",
          })
          .then((res) => {
            const fileName =
                i18n.t("countryoverview.pagetitle") +
                "-" +
                i18n.t("reports.progressreport.actualnumbers");
            this.downloadExcel(fileName, res.data);
          });
    },
    excelCumulativeNumber() {
      axios
          .get("/progressreport/surveycumulativenumber/5/export", {
            responseType: "blob",
          })
          .then((res) => {
            const fileName =
                i18n.t("countryoverview.pagetitle") +
                "-" +
                i18n.t("reports.progressreport.cumulative");
            this.downloadExcel(fileName, res.data);
          });
    },
    excelTotalNumber() {
      axios
          .get("/progressreport/surveytotalnumber/export", {
            responseType: "blob",
          })
          .then((res) => {
            const fileName =
                i18n.t("reports.progressreport.alllatestsurvey") +
                "-" +
                i18n.t("reports.progressreport.allcountriessince");
            this.downloadExcel(fileName, res.data);
          });
    },
    excelByCountry() {
      axios
          .get("/progressreport/countrysubmission/export", {
            responseType: "blob",
          })
          .then((res) => {
            const fileName =
                i18n.t("reports.progressreport.latestsuveypercountry") + "";
            this.downloadExcel(fileName, res.data);
          });
    },
    excelResponseRate() {
      axios
          .get("/progressreport/responserate/export", {responseType: "blob"})
          .then((res) => {
            const fileName = i18n.t("reports.progressreport.responserate") + "";
            this.downloadExcel(fileName, res.data);
          });
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], {type: "aplication/xlsx"});
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    this.loadingInAction();

    axios.get("/survey").then((res) => {
      this.survey = res.data;
    });

    axios.get("/progressreport/surveyactualnumber").then((res) => {
      this.actualCurrentSurvey = res.data;
      this.loadingActualCurrent = false;
    });

    axios.get("/progressreport/surveycumulativenumber/5").then((res) => {
      this.cumulativeCurrentSurvey = res.data;
      this.loadingCumulativeCurrent = false;
    });

    axios.get("/progressreport/surveytotalnumber").then((res) => {
      this.totalSurvey = res.data;
      this.loadingTotal = false;
    });

    axios.get("/progressreport/countrysubmission").then((res) => {
      this.byCountrySurvey = res.data;
      this.loadingByCountry = false;
    });

    axios.get("/progressreport/responserate").then((res) => {
      this.responseRate = res.data;
      this.loadingResponseRate = false;
    });
    axios.get("/progressreport/surveyYearEndNumber").then((res) => {
      this.yearsEndTotal = res.data;
      this.loadingYearsEndTotal = false;
    });
  },
  watch: {},
});
